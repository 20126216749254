import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

import logo from '../data/images/Logo.gif';
import socialLink1 from '../data/images/Discord.png';
import socialLink2 from '../data/images/Twitter.png';
import socialLink3 from '../data/images/Ordex.png';

const Navbar = () => {
  return (
    <nav className="navbar">
      <Link to="/">
        <img src={logo} alt="Logo" className="navbar-logo" />
      </Link>
      <div className="social-links">
        <a href="https://discord.gg/hQrAwUxgWU" target='_blank' rel="noreferrer"><img src={socialLink1} alt="Discord" className="social-icon" /></a>
        <a href="https://twitter.com/thedarwins_eth" target='_blank' rel="noreferrer"><img src={socialLink2} alt="Twitter" className="social-icon" /></a>
        <a href="https://ordex.io/collection/eth/thedarwins" target='_blank' rel="noreferrer"><img src={socialLink3} alt="Ordex" className="social-icon" /></a>
      </div>
    </nav>
  );
};

export default Navbar;