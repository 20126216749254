import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import banner from '../data/images/Banner 2.gif';
import './Home.css';

const Home = () => {
  const navigate  = useNavigate(); 

  const navigateToGMTool = () => {
    navigate('/gm-tool'); 
  };

  return (
    <div className="home">
      <div className='home-content'>
        <img src={banner} alt="Banner" className="banner" />
        <h1>The Darwins</h1>
        <p>
          From Cavemen from the prehistoric era to Futuristic aliens, evolution is happening on chain! There's a Darwin for everyone!
        </p>
        <p>
          Our goal is to introduce people to ethscriptions and learn more.
        </p>
        <p>
          We are integrating the Darwins Ethscription collection into the Looperlands.io multi-chain game built upon the Loopring L2 network.
        </p>
        <p>
          Giving Darwins an opportunity to participate in a thriving sub-economy, introducing their community of creators to ethscriptions and giving Darwin holders an opportunity to play as their Darwin and earn money while doing it!
        </p>
        <button onClick={navigateToGMTool} className="gm-tool-button">GM TOOL</button>
      </div>
    </div>
  );
};

export default Home;
