import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import GMify from './components/Gmify';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gm-tool" element={<GMify />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
