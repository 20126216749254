import React from 'react';
import './Footer.css';
const Footer = () => {
  return (
    <footer className="footer">
      © 2024 The Darwins, All rights reserved.
    </footer>
  );
};

export default Footer;
