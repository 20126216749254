import React, { useEffect, useState } from 'react';
import GIF from 'gif.js';
import { decodeFrames } from 'modern-gif'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons'; 
import './Gmify.css';

import placeholderImage from '../data/images/GM GIF.gif';
import gmToolIcon from '../data/images/gmify.png';

const LoadingAnimation = () => (
    <div className="loading-animation">
      <div className="dot dot1"></div>
      <div className="dot dot2"></div>
      <div className="dot dot3"></div>
    </div>
  );

const clothArray = [
    "Acid Hoodie", "Ape Skin", "Blue Puffer", "Blue Sport Shirt", "Clerk",
    "Clown Cloth", "Coat", "Fashion Hoodie", "FBI", "Grey Hoodie",
    "Hoodie Down Blue", "Hoodie Down Cool", "Jacket", "Jester", "King",
    "Lawyer", "Puffer", "Red Hoodie Down", "Red Puffer", "Saber Skin",
    "Safari", "Samurai Armor", "Soldier", "Sport Shirt", "Suit",
    "Suspenders", "Sweater", "Tshirt", "White Suit", "Woodcutter"
];

const steamFrames = [
  '/data/steam/steam1.png',
  '/data/steam/steam2.png',
  '/data/steam/steam3.png',
  '/data/steam/steam4.png',
  '/data/steam/steam5.png',
  '/data/steam/steam6.png',
  '/data/steam/steam7.png',
];

const GMify = () => {
  const [darwinId, setDarwinId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(placeholderImage);
  const [base, setBase] = useState('');
  const [cloth, setCloth] = useState('');
  const [type, setType] = useState('None')
  const [skinTone, setSkinTone] = useState('');
  const [isGif, setIsGif] = useState(false);
  const [downloadURL, setDownloadURL] = useState('');

  useEffect(() => {
    const createDarwin = async () => {
      if (base && cloth && type && skinTone) {
        const gif = new GIF({
          workers: 4,
          quality: 1,
          workerScript: '/data/gif.worker.js',
          width: 420,
          height: 420,
        });

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d', { willReadFrequently: true });
        canvas.width = 420;
        canvas.height = 420;
        ctx.imageSmoothingEnabled = false;

        const drawImage = (imgUrl) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
              ctx.drawImage(img, 0, 0, 420, 420);
              resolve(); 
            };
            img.onerror = reject;
            img.src = imgUrl;
          });
        };          

        const sanitizedCloth = cloth.trim(); 
        let test = 1;
        let i;

        if (isGif) {
          const response = await fetch(base);
          const arrayBuffer = await response.arrayBuffer();
          const frames = await decodeFrames(arrayBuffer, '/data/gif.worker.js')
          const offscreenCanvas = document.createElement('canvas');
          const offscreenCtx = offscreenCanvas.getContext('2d', { willReadFrequently: true });
            offscreenCanvas.width = 28;
            offscreenCanvas.height = 28;

          for (i = 0; i < 13; i++) {
            ctx.clearRect(0, 0, 420, 420);

            const imageData = new ImageData(new Uint8ClampedArray(frames[i].data), 28, 28);
            offscreenCtx.putImageData(imageData, 0, 0);            
            ctx.drawImage(offscreenCanvas, 0, 0, 420, 420);

            if (clothArray.includes(sanitizedCloth)) {
              await drawImage(`/data/attributes/Cloth/${cloth}.png`);
              if (type !== 'None') {
                await drawImage(`/data/attributes/SkinTone/${type}.png`);
              } else {
                await drawImage(`/data/attributes/SkinTone/${skinTone}.png`);
              }
            } else {
              if (type !== 'None') {
                await drawImage(`/data/attributes/Arm/${type}.png`);
                await drawImage(`/data/attributes/SkinTone/${type}.png`);
              } else {
                await drawImage(`/data/attributes/SkinTone/${skinTone}.png`);
                await drawImage(`/data/attributes/Arm/${skinTone}.png`);
              }
            }

            await drawImage(steamFrames[test])
              if (test === 6 ) {
                test = 0
              } else { test++ }

            gif.addFrame(canvas, {copy: true, delay: 150});
          }
      } else {
          for (const frameSrc of steamFrames) {
            ctx.clearRect(0, 0, 420, 420);
  
            await drawImage(base); 
            if (clothArray.includes(sanitizedCloth)) {
              await drawImage(`/data/attributes/Cloth/${cloth}.png`);
              if (type !== 'None') {
                await drawImage(`/data/attributes/SkinTone/${type}.png`);
              } else {
                await drawImage(`/data/attributes/SkinTone/${skinTone}.png`);
              }
            } else {
              if (type !== 'None') {
                await drawImage(`/data/attributes/Arm/${type}.png`);
                await drawImage(`/data/attributes/SkinTone/${type}.png`);
              } else {
                await drawImage(`/data/attributes/SkinTone/${skinTone}.png`);
                await drawImage(`/data/attributes/Arm/${skinTone}.png`);
              }
            }
            await drawImage(frameSrc);
            gif.addFrame(canvas, {copy: true, delay: 150});
          }
        }
      
        gif.on('finished', (blob) => {
          const url = URL.createObjectURL(blob);
          setImageSrc(url);
          setDownloadURL(url);
          setBase('');
          setCloth('');
          setType('None');
          setSkinTone('');
          setIsGif(false);
          setIsLoading(false);
        });
          gif.render();
      };
    }

    createDarwin();
  }, [base, cloth, type, skinTone, isGif]);

  const handleInputChange = (e) => {
    setDarwinId(e.target.value);
    setErrorMessage('');
    setDownloadURL('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const id = parseInt(darwinId, 10);
    if(isNaN(id) || id < 1 || id > 10000) {
      setErrorMessage('Please enter a valid Darwin ID (1-10000).');
      return;
    }

    setIsLoading(true); 
    try {
        const response = await axios.post('https://api.ordex.ai/v0.1/items/search', {
            size: 1000,
            filter: {
            collections: ["ETHEREUM_ETHSCRIPTION:thedarwins"],
            names: [`Darwin #${id}`]
            }
        });
        if (response.data.items[0].meta.attributes.length === 1) {
          const unique = response.data.items[0].meta.attributes[0].value;
          setImageSrc(`/data/unique/${unique}.gif`);
          setIsLoading(false);
          setDownloadURL(`/data/unique/${unique}.gif`);
        } else if (response.data.items[0].meta.content[0].mimeType === 'image/gif') {
          setIsGif(true);
          setBase(response.data.items[0].meta.rawContent);
          setCloth(response.data.items[0].meta.attributes[3].value);
          setType(response.data.items[0].meta.attributes[2].value);
          setSkinTone(response.data.items[0].meta.attributes[1].value);
        } else {
          setBase(response.data.items[0].meta.rawContent);
          setCloth(response.data.items[0].meta.attributes[3].value);
          setType(response.data.items[0].meta.attributes[2].value);
          setSkinTone(response.data.items[0].meta.attributes[1].value);
        }
        
    } catch (error) {
      console.error('API request failed:', error);
      setErrorMessage('Failed to fetch data. Please try again.');
      setIsLoading(false);
    } 
  };

  return (
    <div className="gmify-container">
      <div className="left-side">
          <img src={imageSrc} alt="Display" className="display-image" />
      </div>
      <div className="right-side">
        <img src={gmToolIcon} alt="GM Tool Icon" className="gm-tool-icon" />
        <h1>GM TOOL</h1>
        <h3>Turn any darwin into a morning Chad!</h3>
        <form onSubmit={handleSubmit} className='gm-form'>
        <input type="text" value={darwinId} onChange={handleInputChange} placeholder="DARWINS ID" className="darwins-id-input" />
        {errorMessage && <p>{errorMessage}</p>}
        {isLoading ? (
          <LoadingAnimation />
            ) : (
              <button className="gm-button" type="submit">GM</button>
            )}
        </form>
        {downloadURL && (
          <a href={downloadURL} download="gmify-image.gif" title="Save your GIF">
            <FontAwesomeIcon icon={faFloppyDisk} className="download-link"/> 
          </a>
        )}
      </div>
    </div>
  );
};

export default GMify;
